<script setup lang="ts">
import { useHead } from '#imports'
import { Head, Link } from '#components'

useHead({
  htmlAttrs: {
    class: 'legacy'
  }
})
</script>

<template>
  <div class="dashboard">
    <Head>
      <Link
        href="/scripts/jquery-ui-010512/jquery-ui-1.8.16.custom.min.css"
        rel="stylesheet"
        type="text/css"
        title="ui-theme"
      />
      <Link
        type="text/css"
        media="screen, projection"
        src="/css/freshwidget.css"
        rel="stylesheet"
      />
    </Head>

    <div class="container dashpage">
      <div>
        <slot />
      </div>
      <div class="simple-footer">
        <a href="https://geni.us"><img
          src="/images/logo_footer_color.png"
          width="93"
          height="27"
        /></a>
        <div>
          <a
            href="#!"
            onclick="CookieControl.open()"
          >Do not sell my personal information (EU and US-California only)</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.legacy {
  body #ccc[dark] .ccc-button-solid {
    background-color: #00b9ee;
    border-color: #00b9ee;
    color: #fff;
  }

  body #ccc #ccc-notify {
    font-size: 14px;
  }
}
</style>

<style scoped>
.dashboard > .dashpage {
  width: fit-content;
  @media (min-width: 640px) {
    margin-top: 2rem;
  }
}
</style>
